$(window).on("load",function(){
    $('.slide_main').fadeIn();
    $('.slide_main').sliderPro({
         width: 1200,//横幅
         height:424,
        // arrows: false,//左右の矢印
         buttons: false,//ナビゲーションボタンを出す
        // slideDistance:5,//スライド同士の距離
        // visibleSize: '100%',//前後のスライドを表示
        // thumbnailWidth: 297,//サムネイルの横幅
        // thumbnailHeight: 88,//サムネイルの縦幅
        // //thumbnailArrows:true
         breakpoints: {
           767: { //表示方法を変えるサイズ
             width: 376,
             height:251,
        //     thumbnailWidth: 150,//サムネイルの横幅
        //     thumbnailHeight: 44//サムネイルの縦幅
           }
         }
    });
});

$(function(){

});

//-------------------------
//スマホかの確認
//-------------------------
function isPhone() {
    return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0);
}

$(function(){

    //-------------------------
    //スマホのみのプログラム
    //-------------------------
    if (!isPhone())
        return;

        //-------------------------
        //ピックアップスライドショー
        //-------------------------
        $('.pickup-slider').sliderPro({
            width: 280,//横幅
            //height:156,
            visibleSize: '100%',//前後のスライドを表示
            arrows:true,
            autoHeight:true
        });

});

